<template>
    <div>
        <div class="box_i">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="box_select">
                            <div class="select_calendar" @click="select_calendar = !select_calendar">
                                <div class="title_s">
                                    Schedule
                                </div>
                                <div class="col-2 text-end">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="select_calendar" class="calendar_box">
                                <Calendar :selectedDateInput="getCurrentDate()" @selected_date="selectedDate"/>
                                <div class="time_date_mobile">
                                    <SelectTime @timeChanged="timeChanged"/>
                                </div>
                            </div>
                        </div>
                        <div class="box_select">
                            <div class="select_calendar" @click="select_order = !select_order">
                                <div class="title_s">
                                    Order
                                </div>
                                <div class="col-2 text-end">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="select_order" class="calendar_box">
                                <draggable v-model="images">
                                    <transition-group class="box_drop_items">
                                        <div class="box_list_img" v-for="element in images" :key="element.id">
                                            <img :src="element.src" alt="">
                                        </div>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                        <carousel v-if="isMobile"
                                  class="carousel-shopify pt-2"
                                  :mouseDrag="false"
                                  :scrollPerPage="false"
                                  :perPage="1"
                                  :centerMode="true"
                                  :navigationEnabled="true"
                                  :paginationEnabled="false"
                                  :minSwipeDistance="10000"
                                  v-on:navigation-click="pageChange"
                                  :navigation-next-label="nextLabel"
                                  :navigation-prev-label="prevLabel">
                            <slide v-for="image in getImages">
                                <div class="box_img">
                                    <img :src="image.image_url">
                                </div>
                                <div class="box_select">
                                    <div class="select_calendar" @click="select_product = !select_product">
                                        <div class="title_s">
                                            Product Tagging
                                        </div>
                                        <div class="col-2 text-end">
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="select_product" class="calendar_box">
                                        <div class="box_search_i">
                                            <input @keyup="keypressSearchProduct" type="text" class="search_prod_inp" placeholder="name">
                                            <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                        </div>
                                        <h3 class="product-not-found" v-if="shopify_products.length === 0">Products not found</h3>
                                        <div v-for="prod in shopify_products" class="box_search_prod_shopify">
                                            <div class="prod_info">
                                                <div class="prod_info_item">{{ prod.title }}</div>
                                                <div class="prod_info_item prod_info_secondary">ID {{ prod.id }}</div>
                                            </div>
                                            <div class="prod_options_container">
                                                <div class="prod_image_item">
                                                    <img class="prod_image" v-if="prod.image" :src="prod.image" alt="">
                                                </div>
                                                <div class="prod_add_container">
                                                    <div class="box_add_p" style="width:auto;">
                                                        <template v-if="publish_model.tag_products[getImages[current_image].id] != undefined && isShopifyProductSelected(prod.id, getImages[current_image].id)">
                                                            <button class="btn_added_prod" @click="addProdModal(prod, getImages[current_image].id)">Added</button>
                                                        </template>
                                                        <button v-else-if="publish_model.tag_products[getImages[current_image].id] == undefined ||
                                                                    (publish_model.tag_products[getImages[current_image].id] != undefined &&
                                                                    publish_model.tag_products[getImages[current_image].id].length < 1)"
                                                                    class="btn_add_prod" @click="addProdModal(prod, getImages[current_image].id)">Add</button>
                                                        <button v-else class="btn_add_prod disabled" disabled>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div>To add new tags, click on the empty space. Please note that removing tags from this box will also remove them from Shopify.</div>
                                    </div>
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Tags</div>
                                        <br/>
                                        <multiselect v-model="tags[getImages[current_image].id]"
                                                     :options="available_tags[getImages[current_image].id]"
                                                     selectLabel=""
                                                     deselectLabel=""
                                                     :multiple="true"
                                                     :taggable="true"
                                                     :close-on-select="false"
                                                     :hide-selected="true"
                                                     placeholder="Add tags"
                                                     @tag="updateTag"/>
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Description</div>
                                        <br/>
                                        <textarea v-model="description[getImages[current_image].id]" placeholder="Write a caption... " class="textarea"/>
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Picture Name</div>
                                        <br/>
                                        <input v-model="picture_name[getImages[current_image].id]" type="text" class="input_text_stile" placeholder="Type something...">
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Alt Text</div>
                                        <br/>
                                        <input v-model="alt_text[getImages[current_image].id]" type="text" class="input_text_stile" placeholder="Type something..." >
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <AzureAiImageDescription :image_id="getImages[current_image].id" :image="image.image_url" @updateAltText="updateAltText" @updatePictureName="updatePictureName"/>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                    <div v-if="!isMobile" class="col">
                        <carousel class="carousel-shopify p-2"
                                  :adjustableHeight="true"
                                  :mouseDrag="false"
                                  :scrollPerPage="false"
                                  :perPage="1"
                                  :centerMode="true"
                                  :navigationEnabled="true"
                                  :paginationEnabled="false"
                                  v-on:navigation-click="pageChange"
                                  :navigation-next-label="nextLabel"
                                  :navigation-prev-label="prevLabel">
                            <slide v-for="image in getImages" :style="{height: 'fit-content'}">
                                <div class="box_img">
                                    <img :src="image.image_url">
                                </div>
                                <div class="box_select with-border">
                                    <div class="select_calendar" @click="select_product = !select_product">
                                        <div class="title_s">
                                            Product Tagging
                                        </div>
                                        <div class="col-2 text-end">
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="select_product" class="calendar_box">
                                        <div class="box_search_i">
                                            <input @keyup="keypressSearchProduct" type="text" class="search_prod_inp" placeholder="name">
                                            <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                        </div>
                                        <h3 class="product-not-found" v-if="shopify_products.length === 0">Products not found</h3>
                                        <div v-for="(prod, num) in shopify_products">
                                            <div v-if="num < current_prods_page * 2 && num >= (current_prods_page - 1) * 2" class="box_search_prod_shopify">
                                                <div class="prod_info">
                                                    <div class="prod_info_item">{{ prod.title }}</div>
                                                    <div class="prod_info_item prod_info_secondary">ID {{ prod.id }}</div>
                                                </div>
                                                <div class="prod_options_container">
                                                    <div class="prod_image_item">
                                                        <img class="prod_image" v-if="prod.image" :src="prod.image" alt="">
                                                    </div>
                                                    <div class="prod_add_container">
                                                        <div class="box_add_p" style="width:auto;">
                                                            <template v-if="publish_model.tag_products[getImages[current_image].id] != undefined && isShopifyProductSelected(prod.id, getImages[current_image].id)">
                                                                <button class="btn_added_prod" @click="addProdModal(prod, getImages[current_image].id)">Added</button>
                                                                <button class="btn_added_prod" @click="generateAiDescription(prod)">Create AI Description</button>
                                                            </template>
                                                            <button v-else-if="publish_model.tag_products[getImages[current_image].id] == undefined ||
                                                                    (publish_model.tag_products[getImages[current_image].id] != undefined &&
                                                                    publish_model.tag_products[getImages[current_image].id].length < 1)"
                                                                    class="btn_add_prod" @click="addProdModal(prod, getImages[current_image].id)">Add</button>
                                                            <button v-else class="btn_add_prod disabled" disabled>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 d-flex justify-content-between align-items-center">
                                            <button :disabled="current_prods_page <= 1"
                                                    class="btn-carousel"
                                                    :class="{disabled: current_prods_page <= 1}"
                                                    @click="current_prods_page--">Prev</button>
                                            <span class="mt-3">{{current_prods_page}}</span>
                                            <button :disabled="current_prods_page >= shopify_products.length / 2"
                                                    class="btn-carousel"
                                                    :class="{disabled: current_prods_page >= shopify_products.length / 2}"
                                                    @click="current_prods_page++">Next</button>
                                        </div>
                                    </div>
                                </div>
                                <ShopifyAI :product="selected_product" :brand_image="brand_image" @updateDescription="updateDescription" @updateTags="updateTags"/>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div>To add new tags, click on the empty space. Please note that removing tags from this box will also remove them from Shopify.</div>
                                    </div>
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Tags</div>
                                        <multiselect v-model="tags[getImages[current_image].id]"
                                                     :options="available_tags[getImages[current_image].id]"
                                                     selectLabel=""
                                                     deselectLabel=""
                                                     :multiple="true"
                                                     :taggable="true"
                                                     :close-on-select="false"
                                                     :hide-selected="true"
                                                     placeholder="Add tags"
                                                     @tag="updateTag"/>
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Description</div>
                                        <br/>
                                        <textarea v-model="description[getImages[current_image].id]" placeholder="Write a caption... " class="textarea"/>
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Picture Name</div>
                                        <input v-model="picture_name[getImages[current_image].id]" type="text" class="input_text_stile" placeholder="Type something...">
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div class="text_st1 w_text">Add Alt Text</div>
                                        <input v-model="alt_text[getImages[current_image].id]" type="text" class="input_text_stile" placeholder="Type something..." >
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <div>Include product and company name in picture name and alt text</div>
                                        <div class="input_checkbox cp" @click="includeProdAndComNames(getImages[current_image].id)">
                                            <div class="checked_icon" v-if="include_prod_and_com_names">&#10004;</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box_form2">
                                    <div class="box_form2_item">
                                        <AzureAiImageDescription :image_id="getImages[current_image].id" :image="image.image_url" @updateAltText="updateAltText" @updatePictureName="updatePictureName"/>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                        <div class="time_date" v-if="select_calendar">
                            <SelectTime @timeChanged="timeChanged"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_box">
            <button class="btn_post_n" @click="publish">Publish</button>
        </div>
        <div class="modal" id="modalLoginShopify" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Shopify Account</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <a href="/app/how-connect-to-shopify" target="_blank">
                                How to connect your store
                            </a>
                        </div>
                        <div class="mb-3">
                            <label for="domainControl" class="form-label">Shop Domain</label>
                            <input @keypress="shop_is_valid = false"
                                   v-model="shopDomain"
                                   :class="{ 'is-invalid': shop_is_valid }"
                                   type="text"
                                   id="domainControl"
                                   class="form-control"
                                   placeholder="yourshop.myshopify.com">
                            <div id="shopSignUpFeedback" class="invalid-feedback">{{ shop_is_valid }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="tokenControl" class="form-label">Token</label>
                            <input @keypress="token_is_valid = false"
                                   v-model="token"
                                   :class="{ 'is-invalid': token_is_valid }"
                                   type="text"
                                   id="tokenControl"
                                   class="form-control"
                                   placeholder="Token">
                            <div id="tokenSignUpFeedback" class="invalid-feedback">{{ token_is_valid }}</div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                        <button class="btn_post_n" @click="saveShop">Save Shop</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalSuccessPosting" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header d-flex justify-content-center ">
                        <h5 class="modal-title title_modal_h">Great Job!</h5>
                    </div>
                    <div class="modal-body box_md_t">
                        <div class="title_mst3">
                            Would you like to continue
                        </div>
                        <div class="title_mst3 mt-2">
                            working on the same project?
                        </div>
                    </div>
                    <div class="modal-footer box_btn_modal mb-4">
                        <button class="btn_post_n2" @click="goToHome">Go Home</button>
                        <button class="btn_post_n" data-bs-dismiss="modal" aria-label="Close">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalAddImagesToProduct" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="mt-5 title_mst3">Do you want to add/remove all images to selected product?</div>
                    </div>
                    <div class="modal-footer box_btn_modal mb-4">
                        <button class="btn_post_n" @click="addToOne">No</button>
                        <button class="btn_post_n" @click="addToAll">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainLayout from "../../layouts/MainLayout";
import Calendar from "../../components/Calendar";
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";
import {Modal} from 'bootstrap'
import moment from 'moment-timezone'
import draggable from 'vuedraggable'
import AzureAiImageDescription from "../AzureAiImageDescription";
import { Carousel, Slide } from 'vue-carousel';
import helpFunctions from "../../helpFunctions";
import Multiselect from 'vue-multiselect';
import SelectTime from "../SelectTime";
import ShopifyAI from "../ShopifyAI";
import ('../../css/custom.css');
import ('../../css/carousel.css');

export default {
    name: 'Shopify',
    components: {
        ShopifyAI,
        SelectTime,
        MainLayout,
        Calendar,
        draggable,
        AzureAiImageDescription,
        Carousel,
        Slide,
        Multiselect,
    },
    props: {
        selectedImages: Array,
        brand_image: null,
        company_name: null
    },
    data() {
        return {
            shopify_connect: false,
            token: '',
            shopDomain: '',
            am_pm: 'AM',
            hour: '0',
            minute: '00',
            selected_date: null,
            job: {},
            select_product: false,
            select_calendar: false,
            select_order: false,
            modalLoginShopify: null,
            modalSuccessPosting: null,
            modalAddImagesToProduct: null,
            shop_is_valid: false,
            token_is_valid: false,
            error_form: false,
            added_prod: null,
            shopify_products: [],
            current_image: 0,
            current_prods_page: 1,
            images: [],
            tags: [],
            available_tags: [],
            alt_text: {},
            picture_name: {},
            description: {},
            selected_product: null,
            selected_image_id: null,
            nextLabel: '<span class="btn-carousel btn-carousel_next">Next</span>',
            prevLabel: '<span class="btn-carousel btn-carousel_prev">Prev</span>',
            publish_model: {},
            include_prod_and_com_names: false
        }
    },
    mounted() {
        this.publish_model = helpFunctions.publish_model;
        this.publish_model.image_jobs_id = this.$route.params.id;
        this.modalLoginShopify = new Modal(document.getElementById('modalLoginShopify'));
        this.modalSuccessPosting = new Modal(document.getElementById('modalSuccessPosting'));
        this.modalAddImagesToProduct = new Modal(document.getElementById('modalAddImagesToProduct'));
        this.getJobs();
        this.shopifyConnect();
        this.selectedImages.forEach(el => {
            this.tags[el] = [];
            this.available_tags[el] = [];
            this.alt_text[el] = '';
            this.picture_name[el] = '';
            this.description[el] = '';
            this.publish_model.tag_products[el] = [];
        });
        this.selected_date = this.getCurrentDate();
        this.hour = helpFunctions.getCurrentHour(5);
        this.minute = helpFunctions.getCurrentMinutes(5);
        this.am_pm = helpFunctions.getCurrentMeridiem(5);
    },
    destroyed() {
        if (this.modalLoginShopify) {
            this.modalLoginShopify = null;
        }
        if (this.modalSuccessPosting) {
            this.modalSuccessPosting = null;
        }
        if (this.modalAddImagesToProduct) {
            this.modalAddImagesToProduct = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async shopifyConnect() {
            this.showLoader();
            const resp = await this.$http.getAuth(`${this.$http.apiUrl()}shopify-connect`);
            this.shopify_connect = resp.data.data;
            if (!this.shopify_connect) {
                this.modalLoginShopify.show();
            }
            this.hideLoader();
        },
        async loginShopify() {
            let error_form = false;
            if (!this.shop) {
                this.shop_is_valid = 'This field is required';
                error_form = true;
            }
            if (error_form) {
                return;
            }
            this.showLoader();
            try {
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}login-shopify`, {
                    shop: this.shop,
                });
                window.open(resp?.data?.data);
                this.modalLoginShopify.hide();
            } catch (e) {
                const message = 'Invalid login or password';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async saveShop() {
            this.error_form = false;
            this.shopFieldValidate(this.shopDomain);
            this.tokenFieldValidate(this.token);
            if (this.error_form) {
                return;
            }
            this.showLoader();
            try {
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}save-shop`, {
                    shop: this.shopDomain,
                    token: this.token,
                });
                if (response.data.data.scopes) {
                    this.shop_is_valid = 'Need access scopes: ' + response.data.data.scopes;
                    return;
                } else {
                    this.shopify_connect = response.data.data;
                    this.modalLoginShopify.hide();
                }
            } catch (error) {
                errorMessage('Server response error');
            } finally {
                this.hideLoader();
            }
        },
        shopFieldValidate(shop) {
            if (!shop.endsWith('myshopify.com')) {
                this.shop_is_valid = 'This field is invalid';
                this.error_form = true;
            }
            if (!shop) {
                this.shop_is_valid = 'This field is required';
                this.error_form = true;
            }
        },
        tokenFieldValidate(token) {
            if (!token.startsWith('shpat_')) {
                this.token_is_valid = 'This field is invalid';
                this.error_form = true;
            }
            if (!token) {
                this.token_is_valid = 'This field is required';
                this.error_form = true;
            }
        },
        addProdModal(prod, image_id) {
            this.selected_product = prod;
            this.selected_image_id = image_id;
            this.modalAddImagesToProduct.show();
        },
        addToAll() {
            this.getImages.forEach(image => {
                this.addProd(this.selected_product, image.id);
            });
            this.modalAddImagesToProduct.hide();
        },
        addToOne() {
            this.addProd(this.selected_product, this.selected_image_id);
            this.modalAddImagesToProduct.hide();
        },
        addProd(prod, image_id) {
            let savedProduct = this.publish_model.tag_products[image_id].find(obj => {
                return obj.product_id === prod.id;
            });
            if (savedProduct === undefined) {
                if (this.publish_model.tag_products[image_id].length < 1) {
                    let added_prods = this.publish_model.tag_products;
                    added_prods[image_id].push({
                        product_id: prod.id,
                        product_name: prod.title,
                        image_url: prod.image
                    });
                    this.publish_model.tag_products = added_prods;
                    if(prod.tags) {
                        this.available_tags[image_id] = prod.tags;
                        this.tags[image_id] = prod.tags;
                    }
                }
            }
            else {
                let index = this.publish_model.tag_products[image_id].findIndex((obj) => obj.product_id === prod.id);
                if (index > -1) {
                    this.publish_model.tag_products[image_id].splice(index, 1);
                }
            }
            //this.publish_model.is_replaced = false;
            this.select_product = !this.select_product;
            this.select_product = !this.select_product;
        },
        generateAiDescription(prod) {
            this.selected_product = prod;
        },
        isShopifyProductSelected(product_id, image_id) {
            let index = this.publish_model.tag_products[image_id].findIndex((obj) => obj.product_id === product_id);
            return index > -1;
        },
        /*replaceProd(prod) {
            this.added_prod = prod;
            this.publish_model.is_replaced = true;
        },*/
        async keypressSearchProduct(event) {
            let search_text = event.target.value;
            if (search_text && search_text.length > 2) {
                this.showLoader();
                try {
                    const result = await this.$http.postAuth(`${this.$http.apiUrl()}search-product-shopify`, {
                        search: search_text
                    });
                    this.shopify_products = result?.data?.data;
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            } else {
                this.shopify_products = [];
            }
        },
        async getJobs() {
            this.showLoader();
            if (!this.$route.params.id) {
                return errorMessage('OOPS... No ID job!');
            }
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}get-job/${this.$route.params.id}`);
                this.job = result?.data?.data?.job || {};
                this.images = this.job.worked_image.filter(el => this.selectedImages.includes(el.id)).map(el => ({
                    id: el.id,
                    src: el.image_url
                }));
                /*this.images = this.job.images.filter(el => this.selectedImages.includes(el.worked_img.id)).map(el => ({
                    id: el.worked_img.id,
                    src: el.worked_img.image_url
                }));*/
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        getCurrentDate() {
            return moment().format('YYYY-MM-DD');
        },
        goToHome() {
            this.modalSuccessPosting.hide();
            this.$router.push({to: '/', name: "Home"}).then();
        },
        async publish() {
            if (!this.shopify_connect) {
                this.modalLoginShopify.show();
                return;
            }
            this.publish_model.date_publication = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
            this.publish_model.sequence_pictures = this.images.map(e => { return e.id; }) ?? [];
            this.publish_model.platform = 'shopify';
            this.selectedImages.forEach(el => {
                this.publish_model.tag_products[el].forEach(product => {
                    product.tags = this.tags[el].toString();
                    product.alt_text = this.alt_text[el];
                    product.picture_name = this.picture_name[el];
                    product.description = this.description[el];
                });
            });
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}post-images`, this.publish_model);
                this.modalSuccessPosting.show();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.modalLoginShopify.hide();
            this.hideLoader();
        },
        updateTag(newTag) {
            this.available_tags[this.getImages[this.current_image].id].push(newTag);
            this.tags[this.getImages[this.current_image].id].push(newTag);
        },
        updateAltText(new_alt_text) {
            let alt_text_temp = '';
            if(this.include_prod_and_com_names) {
                alt_text_temp = this.altTextProdAndCompany(new_alt_text[1]) + ". " + new_alt_text[0];
            }
            else {
                alt_text_temp = new_alt_text[0];
            }
            this.$set(this.alt_text, new_alt_text[1], alt_text_temp);
        },
        updatePictureName(new_picture_name) {
            let picture_name_temp = '';
            if(this.include_prod_and_com_names) {
                picture_name_temp = this.pictureNameProdAndCompany(new_picture_name[1]) + "-" + new_picture_name[0];
            }
            else {
                picture_name_temp = new_picture_name[0];
            }
            this.$set(this.picture_name, new_picture_name[1], picture_name_temp);
            this.select_product = !this.select_product;
            this.select_product = !this.select_product;
        },
        includeProdAndComNames(id) {
            this.include_prod_and_com_names = !this.include_prod_and_com_names;
            let alt_text_temp = '';
            let picture_name_temp = '';
            if(this.include_prod_and_com_names) {
                if(this.alt_text[id]) {
                    alt_text_temp = this.altTextProdAndCompany(id) + ". " + this.alt_text[id];
                }
                else {
                    alt_text_temp = this.altTextProdAndCompany(id);
                }
                if(this.picture_name[id]) {
                    picture_name_temp = this.pictureNameProdAndCompany(id) + "-" + this.picture_name[id];
                }
                else {
                    picture_name_temp = this.pictureNameProdAndCompany(id);
                }
            }
            else {
                alt_text_temp = this.alt_text[id].replace(this.altTextProdAndCompany(id), "");
                alt_text_temp = alt_text_temp.replace(". ", "");
                picture_name_temp = this.picture_name[id].replace(this.pictureNameProdAndCompany(id), "");
            }
            this.$set(this.alt_text, id, alt_text_temp);
            this.$set(this.picture_name, id, picture_name_temp);
            this.select_product = !this.select_product;
            this.select_product = !this.select_product;
        },
        altTextProdAndCompany(id) {
            let prod_title = null;
            if(this.selected_product) {
                prod_title = this.selected_product.title;
            }
            else if(this.publish_model.tag_products[id] && this.publish_model.tag_products[id].length > 0) {
                prod_title = this.publish_model.tag_products[id][0].product_name;
            }
            if(prod_title) {
                return prod_title + ' by ' + this.company_name;
            }
            return '';
        },
        pictureNameProdAndCompany(id) {
            let prod_title = null;
            if(this.selected_product) {
                prod_title = this.selected_product.title;
            }
            else if(this.publish_model.tag_products[id] && this.publish_model.tag_products[id].length > 0) {
                prod_title = this.publish_model.tag_products[id][0].product_name;
            }
            if(prod_title) {
                return prod_title.toLowerCase().replaceAll(' ', '-') + '-by-' + this.company_name.toLowerCase().replaceAll(' ', '-');
            }
            return '';
        },
        updateDescription(data) {
            this.description[this.getImages[this.current_image].id] = data;
            this.select_product = !this.select_product;
            this.select_product = !this.select_product;
        },
        updateTags(data) {
            this.available_tags[this.getImages[this.current_image].id] = [...data[0], ...data[1]];
            this.tags[this.getImages[this.current_image].id] = [...data[0], ...data[1]];
            this.select_product = !this.select_product;
            this.select_product = !this.select_product;
        },
        pageChange(index) {
            this.include_prod_and_com_names = false;
            this.select_product = false;
            this.current_image = helpFunctions.pageChange(index, this.current_image, this.selectedImages.length);
        },
        selectedDate(data) {
            this.selected_date = data;
        },
        timeChanged(new_time) {
            this.hour = new_time[0];
            this.minute = new_time[1];
            this.am_pm = new_time[2];
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 992;
        },
        getImages() {
            if(this.job?.worked_image) {
                return this.job?.worked_image.filter(el => this.selectedImages.includes(el.id));
            }
            /*if (this.job?.images) {
                return this.job?.images.filter(el => this.selectedImages.includes(el.worked_img.id));
            }*/
        }
    }
}
</script>

<style lang="scss" scoped>
.col {
    width: 50%;
}
.carousel {
    .btn {
        border: 0.5px solid #494949 !important;
        border-radius: 10px;
        padding: 7px 33px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: white;
        font-style: normal;
        box-sizing: border-box;
        cursor: pointer;
        white-space: nowrap;
    }
}
.product-not-found {
    margin: 24px 20px 0 20px;
}
.with-border {
    border: 1px solid #494949;
}
.title_mst3 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #494949;
}
.box_md_t {
    margin: 30px 0;
}
.box_btn_modal {
    display: flex;
    justify-content: center;
    gap: 40px;
}
.modal-footer {
    border-top: none;
}
.box_i {
    padding-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.title_modal_h {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 20px 0;
}
.box {
    display: flex;
    margin-top: 64px;
    padding: 21px;
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    gap: 47px;
}
.box_img {
    //width: 313px;
    //height: 300px;
    width: 28%;
    height: auto;
}
.box_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.textarea {
    width: 100%;
    height: 150px;
    padding-top: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
textarea::-webkit-input-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
textarea:-moz-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.text_st1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.input_text_stile {
    height: 30px;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 6px 19px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
}
.box_form2_item {
    display: flex;
    align-items: center;
}
.w_text {
    width: 60%;
}
.box_form2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 45px;
    padding-left: 35px;
}
.btn_box {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding-top: 170px;
    margin-bottom: 80px;
}
.select_calendar {
    display: flex;
    justify-content: space-between;
    padding: 21px 24px 21px 34px;
    height: 65px;
    align-items: center;
}
.box_select {
    cursor: pointer;
    margin-top: 27px;
    background: white;
    border-radius: 5px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.15));
}
.calendar_box {
    padding: 33px;
}
.time_date_mobile {
    display: none;
}
.time_date {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}
.search_icon {
    position: absolute;
    left: 50px;
    top: 110px;
    cursor: pointer;

    @media only screen and (max-width: 992px) {
        top: 90px;
    }
}
.search_prod_inp {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 41px;
    width: 100%;
    padding-left: 45px;
}
/*.box_search_i {
    position: relative;
}*/
.box_search_prod_shopify {
    margin-top: 33px;
    background: white;
    border: 0.2px solid #494949;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
}
.prod_options_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: end;
}
.prod_add_container {
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.prod_add_container .box_add_p:last-child {
    padding: 10px 0 10px 5px;
    margin-right: 0;
}
.prod_image {
    width: 100%;
    height: auto
}
.prod_image_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}
.prod_info {
    width: 60%;
    margin: 10px;
}
.box_add_p {
    padding: 10px 5px;
    width: 35%;
    display: flex;
    justify-content: end;
    align-items: end;
    margin-right: 10px;
}
.prod_info_item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: black;
    padding: 5px 0;
}
.prod_info_secondary {
    font-size: 12px;
    color: gray;
}
.box_list_img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.box_list_img {
    background: white;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    //width: 100%;
    //height: 100%;
    object-fit: cover;
}
.box_list_img {
    flex: 1 1 9em;
    max-width: 150px;
    height: 160px;
}
.box_drop_items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    //width: 100%;
    //display: grid;
    //grid-gap: 20px;
    //grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
    //grid-template-rows: 150px 1fr;
}
.input_checkbox {
    width: 22px;
    height: 22px;
    background: white;
    border: 0.3px solid #494949;
    float: left;
    margin-left: 20px;
}
.checked_icon {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 992px) {
    .col {
        width: 100%;
        flex: auto;
    }
    .box_i {
        padding-top: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .prod_info {
        padding: 18px 5px 18px 18px;
        width: 65%;
    }
    .box_add_p {
        padding: 13px 15px 13px 5px;
        width: 35%;
    }
    .btn_box {
        margin-bottom: 0px;
    }
    .box_list_img {
        max-width: 150px;
    }
    .box_drop_items {
        gap: 7px;
    }
    .box_btn_modal {
        gap: 5px;
    }
    .time_date_mobile {
        padding-top: 30px;
        display: flex;
        justify-content: center;
    }
    .calendar_box {
        padding: 14px 28px 30px 28px;
    }
    .w_text {
        width: 100%;
    }
    .btn_box {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    .box_form2 {
        padding-right: 17px;
        padding-left: 1px;
    }
    .box_select {
        width: 100%;
    }
    .box_img {
        width: 104px;
        height: 100px;
    }
    .box {
        margin-top: 26px;
        padding: 7px;
        gap: 17px;
    }
    .box_form2_item {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}
</style>
