<template>
    <div class="page_container">
        <div class="btn btn_d" @click="downloadImages">Download {{selectedImages.length}} images</div>
    </div>
</template>

<script>
import MainLayout from "../../layouts/MainLayout";
import { errorMessage } from '../../services/messages';
import {mapMutations} from "vuex";
import axios from "axios";

export default {
    name: 'DownloadImages',
    components: {
        MainLayout,
    },
    props: {
        selectedImages: Array,
    },
    data() {
        return {
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async downloadImages() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}worked-images/` + this.$route.params.id);
                const images = response?.data?.data;
                let images_url = [];
                images.forEach(image => {
                    if(this.selectedImages.includes(image.id)) {
                        images_url.push(image.image_url);
                    }
                });
                const download_images = await this.$http.postAuth(`${this.$http.apiUrl()}download-images`, {
                    images_url: images_url
                });
                if (download_images) {
                    axios.get(download_images.data, {responseType: "blob"}).then(zip_file => {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(new Blob([zip_file.data]));
                        link.download = "images.zip";
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                    });
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
                this.hideLoader();
            }
            this.hideLoader();
        },
    },
}
</script>

<style lang="scss" scoped>
.page_container {
    display: flex;
    justify-content: center;
    padding: 100px 0;
}
</style>
