<template>
    <MainLayout :vh_header="true" :is-show-header=true>
        <div class="container_page">
            <div>
                <div class="title_page">Upload Files</div>
                <span>Select images for uploading</span>
            </div>
            <div class="d-flex justify-content-end mb-5" style="margin-top: -60px;" v-if="!show_service">
                <button class="btn_accept" @click="selectAllImages">Select All Images</button>
            </div>
            <GalleryWithSelect ref="gallery_with_select" :images="images" :limit="1000" @updateSelectedImages="updateSelectedImages"/>
            <div class="d-flex justify-content-center mt-5 mb-5" v-if="!show_service">
                <button class="btn_accept" @click="showService">Accept</button>
            </div>
            <div v-if="show_service">
                <!--<span class="pt-5 pb-3" style="float: right;">Please select not more than 10 images at a time if you want to share them</span>-->
                <div class="select_socialite">
                    <div class="title_2 pr-50">Upload to</div>
                    <div>
                        <select class="form-select" v-model="platform" @change="selectPlatform(platform)">
                            <option selected disabled hidden value="">Choose a platform...</option>
                            <option v-for="(item, index) in platforms_items" :key="index" :value="item">{{ item }}</option>
                        </select>
                        <div id="fileFormatFeedback" class="invalid-feedback">
                            Please choose a file format.
                        </div>
                    </div>
                </div>
                <DownloadImages v-if="platform==='Download'" :selectedImages="selectedImages"/>
                <Instagram v-if="platform==='Instagram'" :selectedImages="selectedImages" :brand_image="brand_image" :company_name="company_name"/>
                <Shopify v-if="platform==='Shopify'" :selectedImages="selectedImages" :brand_image="brand_image" :company_name="company_name"/>
                <Amazon v-if="platform==='Amazon'" :selectedImages="selectedImages" :brand_image="brand_image" :company_name="company_name"/>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {errorMessage} from "../services/messages";
import {mapMutations} from "vuex";
import Instagram from "./FileSchedule/Instagram";
import Shopify from "./FileSchedule/Shopify";
import Amazon from "./FileSchedule/Amazon";
import DownloadImages from "@/components/FileSchedule/DownloadImages";
import GalleryWithSelect from './GalleryWithSelect';
import ('../css/custom.css');

export default {
    name: 'UploadFilesForm',
    components: {
        MainLayout,
        Instagram,
        Shopify,
        Amazon,
        DownloadImages,
        GalleryWithSelect
    },
    data() {
        return {
            all_platforms_items: [
                'Download',
                'Shopify',
                'Amazon',
                'Instagram',
            ],
            platform: 'Download',
            platforms_items: [],
            job_id: this.$route.params.id,
            job: {},
            images: [],
            selectedImages: [],
            show_service: false,
            brand_image: null,
            company_name: null
        }
    },
    mounted() {
        this.platform = this.$route.query.platform ?? 'Download';
        this.getJobs();
        this.getChatGptDescription();
        this.getCompanyName();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async getChatGptDescription() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/get-chat-gpt-description`);
                this.brand_image = response?.data?.data;
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async getCompanyName() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/get-company-name`);
                this.company_name = response?.data?.data;
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        selectPlatform(platform) {
            this.$router.push({query: {platform: platform}});
        },
        async getJobs() {
            this.showLoader();
            if (!this.$route.params.id) {
                return errorMessage('OOPS... No ID job!');
            }
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}get-job/${this.$route.params.id}`);
                this.job = result?.data?.data?.job || {};
                this.images = this.job.worked_image.map(el => ({
                    id: el.id,
                    src: el.image_url
                }));
                /*this.images = this.job.images.map(el => ({
                    id: el.worked_img.id,
                    src: el.worked_img.image_url
                }));*/
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        updateSelectedImages(selectedImages) {
            this.selectedImages = selectedImages;
            this.showService();
            this.show_service = false;
        },
        showService() {
            //if (this.isArchiveSelected || this.selectedImages.length > 10) {
            if (this.isArchiveSelected) {
                this.platforms_items = ['Download'];
                if(this.platform !== 'Download') {
                    this.platform = 'Download';
                    this.selectPlatform(this.platform);
                }
            } else {
                this.platforms_items = this.all_platforms_items;
            }
            this.show_service = this.selectedImages.length > 0;
        },
        selectAllImages() {
            this.$refs.gallery_with_select.selectAllImages();
        },
    },
    computed: {
        isArchiveSelected() {
            return this.images.filter(el => this.selectedImages.includes(el.id )).filter(el => el.src.match(/(Archive)|(\.(zip|rar|7z)$)/g))?.length > 0 || false;
        }
    }
}
</script>

<style lang="scss" scoped>
.container_page {
    padding: 70px 12px;
}
.title_page {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    color: #494949;
}
.form-select {
    border-radius: 19px;
    color: rgba(74, 74, 74, 0.6);
    border-color: rgba(74, 74, 74, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 348px;
}
.form-select:focus {
    border-color: rgba(74, 74, 74, 0.6);
}
.select_socialite {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}
.title_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #494949;
}

@media only screen and (max-width: 992px) {
    .title_page {
        font-size: 18px;
        line-height: 22px;
    }
    .form-select {
        width: 200px;
    }
    .title_2 {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    }
    .select_socialite {
        justify-content: center;
    }
    .btn_accept {
        margin-top: 90px;
    }
}
</style>
