<template>
    <div class="box_select">
        <div class="select_calendar" @click="chatgpt = !chatgpt">
            <div class="title_s">
                AI Data
            </div>
            <div class="col-2 text-end">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                </svg>
            </div>
        </div>
        <div v-if="chatgpt" class="calendar_box_2">
            <div class="mt-3">
                <div class="text_st1 w_text">Current Description</div>
                <br/>
                <textarea v-model="description" placeholder="Current Description... " class="textarea"/>
            </div>
            <div class="mt-3">
                <div class="text_st1 w_text">AI Description</div>
                <br/>
                <textarea v-model="ai_description" placeholder="AI Description... " class="textarea"/>
            </div>
            <div class="mt-3">
                <div class="input_checkbox cp" @click="limit_description_length = !limit_description_length">
                    <div class="checked_icon" v-if="limit_description_length">&#10004;</div>
                </div>
                <div style="float: left;">Limit AI description length to: </div>
                <select v-model="max_description_length" class="time_b" :disabled="!limit_description_length">
                    <option class="h6" v-for="item in ['no limit',3,4,5,6,7,8,9,10]" :value="item">{{ item }}</option>
                </select>
                <div> sentences</div>
            </div>
            <div class="mt-3">
                <button class="btn_generate_alt" @click="generateDescription">Generate Description</button>
                <p class="text-error mt-3" v-if="errors['desc']">{{errors['desc']}}</p>
            </div>
            <div class="mt-3">
                <div class="text_st1 w_text">AI Tags</div>
                <br/>
                <multiselect v-model="ai_tags"
                             :options="available_tags"
                             selectLabel=""
                             deselectLabel=""
                             :multiple="true"
                             :taggable="true"
                             :close-on-select="false"
                             :hide-selected="true"
                             placeholder="Add tags"/>
            </div>
            <div class="mt-3">
                <button class="btn_generate_alt" @click="generateTags">Generate Tags</button>
                <p class="text-error mt-3" v-if="errors['tag']">{{errors['tag']}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {errorMessage} from "../services/messages";
import {mapMutations} from "vuex";
import axios from "axios";
import Multiselect from 'vue-multiselect';

export default {
    name: "AmazonAI",
    components: {
        Multiselect,
    },
    props: {
        product: null,
        brand_image: null
    },
    data() {
        return {
            chatgpt: false,
            description: '',
            ai_description: null,
            ai_tags: [],
            available_tags: [],
            limit_description_length: false,
            max_description_length: 'no limit',
            errors: {}
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async generateDescription() {
            if(this.product) {
            let content = "";
            if (this.brand_image) {
                content = "You are an experienced marketer. Please read about our company: " + this.brand_image + ". Please use this information to create a better description about our new product " + this.product.name + ". The short description of our new product: " + this.description + ".";
            } else {
                content = "You are an experienced marketer. You should sell " + this.product.name + ". The short description of this product is: " + this.description + ". Please write a better description for it.";
            }
            if (this.limit_description_length && this.max_description_length !== 'no limit') {
                content += " The new description length should be not more than " + this.max_description_length + " sentences.";
            }
            try {
                this.showLoader();
                axios.post('https://api.openai.com/v1/chat/completions', {
                    "model": "gpt-3.5-turbo",
                    "messages": [
                        {
                            "role": "user",
                            "content": content,
                        }
                    ],
                    "temperature": 1,
                    "top_p": 1,
                    "n": 1,
                    "stream": false,
                    "max_tokens": 1000,
                    "presence_penalty": 0,
                    "frequency_penalty": 0
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ` + process.env.VUE_APP_CHATGPT_KEY
                    }
                })
                    .then(response => {
                        this.hideLoader();
                        this.ai_description = response.data.choices[0].message.content;
                        this.removeError('desc')
                    });
            } catch (e) {
                this.hideLoader();
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        }
            else {
                this.addError('desc', 'Please, select product to generate description');
            }
        },
        async generateTags() {
            if(this.product) {
            let content = "";
            if (this.brand_image) {
                content = "You are a marketer. Please read a description about our company: " + this.brand_image + ". Please use this information to create 5 short keywords for this product: " + this.product.name + '. ' + this.description + ".";
            } else {
                content = "You are a marketer. Please generate 5 short keywords for this product: " + this.product.name + '. ' + this.description + ".";
            }
            try {
                this.showLoader();
                axios.post('https://api.openai.com/v1/chat/completions', {
                    "model": "gpt-3.5-turbo",
                    "messages": [
                        {
                            "role": "user",
                            "content": content,
                        }
                    ],
                    "temperature": 1,
                    "top_p": 1,
                    "n": 1,
                    "stream": false,
                    "max_tokens": 1000,
                    "presence_penalty": 0,
                    "frequency_penalty": 0
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ` + process.env.VUE_APP_CHATGPT_KEY
                    }
                })
                    .then(response => {
                        this.hideLoader();
                        if (response?.data?.choices[0]?.message?.content) {
                            let temp_tags = response.data.choices[0].message.content.split(/\r?\n/);
                            for (let i = 0; i < temp_tags.length; i++) {
                                temp_tags[i] = temp_tags[i].replace("1.", "").replace("2.", "").replace("3.", "").replace("4.", "").replace("5.", "");
                                temp_tags[i] = temp_tags[i].replace("1)", "").replace("2)", "").replace("3)", "").replace("4)", "").replace("5)", "");
                                temp_tags[i] = temp_tags[i].replace("a.", "").replace("b.", "").replace("c.", "").replace("d.", "").replace("e.", "");
                                temp_tags[i] = temp_tags[i].replace("a)", "").replace("b)", "").replace("c)", "").replace("d)", "").replace("e)", "");
                                temp_tags[i] = temp_tags[i].trim();
                            }
                            this.ai_tags = temp_tags;
                            this.available_tags = temp_tags;
                            this.removeError('tag')
                        }
                    });
            } catch (e) {
                this.hideLoader();
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        }
            else {
                this.addError('tag','Please, select product to generate tags');
            }
        },
        addError(key, message) {
            if (!this.errors[key]) {
                this.errors = { ...this.errors, [key]: message };
            }
        },
        removeError(key) {
            if (this.errors[key]) {
                const { [key]: _, ...newErrors } = this.errors;
                this.errors = newErrors;
            }
        },
    },
    watch: {
        product: {
            handler: function() {
                this.$http.getAuth(`${this.$http.apiUrl()}seller-partner/product/` + this.product.sku + `/full-product-info`).then(response => {
                    this.description = response.data.data;
                })
            },
        },
    }
}
</script>

<style lang="scss" scoped>
.btn_generate_alt {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
    padding: 4px 10px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: white;
    width: 200px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
}
.textarea {
    width: 100%;
    height: 150px;
    padding-top: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
textarea::-webkit-input-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
textarea:-moz-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.text_st1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.select_calendar {
    display: flex;
    justify-content: space-between;
    padding: 21px 24px 21px 34px;
    height: 65px;
    align-items: center;
}
.box_select {
    cursor: pointer;
    margin-top: 27px;
    background: white;
    border-radius: 5px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.15));
}
.with-border {
    border: 1px solid #494949;
}
.calendar_box_2 {
    padding: 0 33px 33px 33px;
}
.input_checkbox {
    width: 22px;
    height: 22px;
    background: white;
    border: 0.3px solid #494949;
    float: left;
    margin-right: 10px;
}
.checked_icon {
    display: flex;
    justify-content: center;
}
.time_b {
    -webkit-appearance: none;
    padding: 5px 14px;
    text-align: center;
    font-style: normal;
    color: #494949;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 5px;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -5px;
}

@media only screen and (max-width: 992px) {
    .btn_generate_alt {
        margin-left: 0;
        margin-top: 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        width: 150px;
        height: 45px;
    }
    .box_select {
        width: 100%;
    }
}
</style>
