<template>
    <UploadFilesForm/>
</template>

<script>
import UploadFilesForm from "../components/UploadFilesForm";
import MainLayout from "../layouts/MainLayout";
import {mapMutations} from "vuex";

export default {
    name: 'UploadFiles',
    components: {
        MainLayout,
        UploadFilesForm
    },
    data() {
        return {
            active: 1,
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        onActive(data) {
            this.active = data;
        },
    },
}
</script>
