<template>
    <div>
        <div class="box_i">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="box_select">
                            <div class="select_calendar" @click="select_product = !select_product">
                                <div class="title_s">
                                    Product {{ getProd }}
                                </div>
                                <div class="col-2 text-end">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="select_product" class="calendar_box">
                                <div class="box_search_i">
                                    <input @keyup="keypressSearchProduct" type="text" class="search_prod_inp" placeholder="name, ID, SKU">
                                    <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                </div>
                                <div v-for="prod in amazon_products" class="box_search_prod_amazon">
                                    <div class="prod_info">
                                        <div class="prod_info_item">{{ prod.name }}</div>
                                        <div class="prod_info_item">SKU {{ prod.sku }}</div>
                                        <div class="prod_info_item">ASIN {{ prod.asin }}</div>
                                    </div>
                                    <template v-if="isMobile">
                                        <div class="prod_options_container">
                                            <div class="prod_add_container">
                                                <div class="box_add_p">
                                                    <button v-if="(!publish_model.is_replaced && added_prod && added_prod['sku'] == prod['sku'])" class="btn_added_prod" @click="addProd(prod)">Added</button>
                                                    <button v-else class="btn_add_prod" @click="addProd(prod)">Add</button>
                                                </div>
                                                <div class="box_add_p">
                                                    <button v-if="(publish_model.is_replaced && added_prod['sku'] == prod['sku'])" class="btn_added_prod" @click="replaceProd(prod)">Replaced</button>
                                                    <button v-else class="btn_add_prod" @click="replaceProd(prod)">Replace</button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="box_add_p">
                                            <button v-if="(!publish_model.is_replaced && added_prod && added_prod['sku'] == prod['sku'])" class="btn_added_prod" @click="addProd(prod)">Added</button>
                                            <button v-else class="btn_add_prod" @click="addProd(prod)">Add</button>
                                        </div>
                                        <div class="box_add_p">
                                            <button v-if="(publish_model.is_replaced && added_prod['sku'] == prod['sku'])" class="btn_added_prod" @click="replaceProd(prod)">Replaced</button>
                                            <button v-else class="btn_add_prod" @click="replaceProd(prod)">Replace</button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="box_select">
                            <div class="select_calendar" @click="select_calendar = !select_calendar">
                                <div class="title_s">
                                    Schedule
                                </div>
                                <div class="col-2 text-end">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="select_calendar" class="calendar_box">
                                <Calendar :selectedDateInput="getCurrentDate()" @selected_date="selectedDate"/>
                                <div class="time_date_mobile">
                                    <SelectTime @timeChanged="timeChanged"/>
                                </div>
                            </div>
                        </div>
                        <div class="box_select">
                            <div class="select_calendar" @click="select_order = !select_order">
                                <div class="title_s">
                                    Order
                                </div>
                                <div class="col-2 text-end">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="select_order" class="calendar_box">
                                <draggable v-model="images">
                                    <transition-group class="box_drop_items">
                                        <div class="box_list_img" v-for="element in images" :key="element.id">
                                            <img :src="element.src" alt="">
                                        </div>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isMobile" class="col">
                        <AmazonAI :product="added_prod" :brand_image="brand_image"/>
                        <div class="time_date" v-if="select_calendar">
                            <SelectTime @timeChanged="timeChanged"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_box">
            <button class="btn_post_n" @click="publish">Publish</button>
        </div>
        <div class="modal" id="modalLoginAmazon" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Amazon seller</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                        <div class="d-block key-message">
                            To connect your account, disable Pop-ups on your iPhone. Head to Settings > Safari > un-toggle Block Pop-ups
                        </div>
                        <button class="btn_post_n" @click="loginAmazon">Connect account</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalSuccessPosting" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="title_mst2">Your post has been scheduled for</div>
                        <div class="title_mst1">{{ getDatePosted() }}</div>
                        <div class="box_md_t title_mst3">Would you like to continue working on the same project?</div>
                    </div>
                    <div class="modal-footer box_btn_modal mb-4">
                        <button class="btn_post_n2" @click="goToHome">No</button>
                        <button class="btn_post_n" data-bs-dismiss="modal" aria-label="Close">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainLayout from '../../layouts/MainLayout';
import Calendar from '../Calendar';
import draggable from 'vuedraggable';
import { Modal } from 'bootstrap';
import {mapMutations} from 'vuex';
import { errorMessage } from '../../services/messages';
import moment from 'moment-timezone';
import helpFunctions from "../../helpFunctions";
import SelectTime from "../SelectTime";
import AmazonAI from "../AmazonAI";
import ('../../css/custom.css');

export default {
    name: 'Amazon',
    components: {
        AmazonAI,
        SelectTime,
        MainLayout,
        Calendar,
        draggable,
    },
    props: {
        selectedImages: Array,
        brand_image: null,
        company_name: null
    },
    data() {
        return {
            user_amazon: false,
            am_pm: 'AM',
            hour: '0',
            minute: '00',
            selected_date: null,
            job: {},
            select_product: false,
            select_calendar: false,
            select_order: false,
            modalLoginAmazon: null,
            modalSuccessPosting: null,
            modal_date: null,
            date_m: null,
            images: [],
            amazon_products: {},
            added_prod: null,
            publish_model: {}
        }
    },
    mounted() {
        this.publish_model = helpFunctions.publish_model;
        this.publish_model.image_jobs_id = this.$route.params.id;
        this.modalLoginAmazon = new Modal(document.getElementById('modalLoginAmazon'));
        this.modalSuccessPosting = new Modal(document.getElementById('modalSuccessPosting'));
        this.getJobs();
        this.getUserAmazon();

        this.selected_date = this.getCurrentDate();
        this.hour = helpFunctions.getCurrentHour(5);
        this.minute = helpFunctions.getCurrentMinutes(5);
        this.am_pm = helpFunctions.getCurrentMeridiem(5);
    },
    destroyed() {
        if (this.modalLoginAmazon) {
            this.modalLoginAmazon = null;
        }
        if (this.modalSuccessPosting) {
            this.modalSuccessPosting = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        showModalConnect() {
            if (!this.user_amazon) {
                this.modalLoginAmazon.show();
            }
        },
        async keypressSearchProduct(event) {
            if (!this.user_amazon) {
                this.modalLoginAmazon.show();
                errorMessage('Please, connect with Amazon');
                return;
            }
            let search_text = event.target.value;
            if (search_text && search_text.length > 2) {
                this.showLoader();
                try {
                    const result = await this.$http.getAuth(`${this.$http.apiUrl()}seller-partner/products/search`, { search: search_text });
                    this.amazon_products = result?.data?.data.data;
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            } else {
                this.amazon_products = {};
            }
        },
        addProd(prod) {
            this.added_prod = prod;
            this.publish_model.is_replaced = false;
        },
        replaceProd(prod) {
            this.added_prod = prod;
            this.publish_model.is_replaced = true;
        },
        async getUserAmazon() {
            this.showLoader();
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}is-amazon-connect`);
                this.user_amazon = result?.data?.data || null;
                this.showModalConnect();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async getJobs() {
            this.showLoader();
            if (!this.$route.params.id) {
                return errorMessage('OOPS... No ID job!');
            }
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}get-job/${this.$route.params.id}`);
                this.job = result?.data?.data?.job || {};
                this.images = this.job.worked_image.filter(el => this.selectedImages.includes(el.id)).map(el => ({
                    id: el.id,
                    src: el.image_url
                }));
                /*this.images = this.job.images.filter(el => this.selectedImages.includes(el.worked_img.id)).map(el => ({
                    id: el.worked_img.id,
                    src: el.worked_img.image_url
                }));*/
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        getDatePosted() {
            return helpFunctions.getDatePosted(this.date_m, this.modal_date);
        },
        getCurrentDate() {
            return moment().format('YYYY-MM-DD');
        },
        goToHome() {
            this.modalSuccessPosting.hide();
            this.$router.push({to: '/', name: "Home"}).then();
        },
        async loginAmazon() {
            this.showLoader();
            try {
                const res = await this.$http.getAuth(`${this.$http.apiUrl()}seller-partner/auth-link`);
                if (res?.data?.success) {
                    localStorage.setItem('strobeart_amazon_redirect', res.data.data?.redirect_link);
                    window.open(res?.data?.data?.auth_link, '_blank');
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async publish() {
            if (!this.user_amazon) {
                this.modalLoginAmazon.show();
                return;
            }
            if (!this.added_prod) {
                alert('Add Product');
                return;
            }
            this.publish_model.date_publication = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
            this.publish_model.sequence_pictures = this.images.map(e => { return e.id; }) ?? [];
            this.publish_model.platform = 'amazon';
            this.publish_model.product_id = this.added_prod['sku'];
            console.log(this.publish_model);
            try {
                this.showLoader();
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}post-images`, this.publish_model);
                this.date_m = response?.data?.data || null;
                this.modal_date = this.hour + ':' + this.minute + '' + this.am_pm;
                this.modalSuccessPosting.show();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        selectedDate(data) {
            this.selected_date = data;
        },
        timeChanged(new_time) {
            this.hour = new_time[0];
            this.minute = new_time[1];
            this.am_pm = new_time[2];
        },
    },
    computed: {
        getProd() {
            if (this.added_prod?.title || '') {
                return ' - ' + this.added_prod?.title || '';
            }
            return '';
        },
        isMobile() {
            return window.innerWidth <= 992;
        },
    }
}
</script>

<style lang="scss" scoped>
.col {
    width: 50%;
}
.title_mst1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #494949;
}
.title_mst2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #494949;
}
.key-message {
    font-size: 14px;
    color: #be9770;
}
.title_mst3 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #494949;
}
.box_md_t {
    margin-top: 30px;
    padding: 60px 20px;
    border-top: 1px solid #494949;

}
.box_btn_modal {
    display: flex;
    justify-content: center;
    gap: 40px;
}
.modal-footer {
    border-top: none;
}
.box_i {
    padding-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.box {
    display: flex;
    margin-top: 64px;
    padding: 21px;
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    gap: 47px;
}
.btn_box {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding-top: 170px;
    margin-bottom: 80px;
}
.select_calendar {
    display: flex;
    justify-content: space-between;
    padding: 21px 24px 21px 34px;
    height: 65px;
    align-items: center;
}
.box_select {
    cursor: pointer;
    margin-top: 27px;
    background: white;
    border-radius: 5px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.15));
}
.calendar_box {
    padding: 33px;
}
.time_date_mobile {
    display: none;
}
.time_date {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}
.box_list_img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.box_list_img {
    background: white;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    //width: 100%;
    //height: 100%;
    object-fit: cover;
    flex: 1 1 9em;
    max-width: 150px;
    height: 160px;
}
.box_drop_items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

@media only screen and (max-width: 992px) {
    .col {
        width: 100%;
        flex: auto;
    }
    .box_i {
        padding-top: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .prod_options_container {
        width: 40%;
        display: flex;
        flex-direction: column;
        margin: 10px;
        align-items: end;
    }
    .prod_add_container {
        display: flex;
        flex-direction: row;
        justify-content: right;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }
    }
    .btn_box {
        margin-bottom: 0px;
    }
    .box_list_img {
        max-width: 150px;
    }
    .box_drop_items {
        gap: 7px;
    }
    .box_btn_modal {
        gap: 5px;
    }
    .title_mst2 {
        font-size: 19px;
    }
    .title_mst1 {
        font-size: 21px;
    }
    .time_date_mobile {
        padding-top: 30px;
        display: flex;
        justify-content: center;
    }
    .time_date {
        display: none;
    }
    .calendar_box {
        padding: 14px 28px 30px 28px;
    }
    .btn_box {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    .box_select {
        width: 100%;
    }
    .box {
        margin-top: 26px;
        padding: 7px;
        gap: 17px;
    }
}
.search_prod_inp {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 41px;
    width: 100%;
    padding-left: 45px;
}
.search_icon {
    position: absolute;
    left: 50px;
    top: 110px;
    cursor: pointer;

    @media only screen and (max-width: 992px) {
        top: 90px;
    }
}
.box_search_prod_amazon {
    margin-top: 33px;
    background: white;
    border: 0.2px solid #494949;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
}
.prod_info {
    width: 60%;
    margin: 10px;
}
.prod_info_item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: black;
    padding: 5px 0;
}
.box_add_p {
    padding: 13px 15px;
    width: 25%;
    display: flex;
    justify-content: end;
    align-items: end;

    @media only screen and (max-width: 992px) {
        padding: 13px 15px 13px 5px;
        width: 35%;
    }
    @media only screen and (max-width: 600px) {
        justify-content: center;
    }
}
</style>
