<template>
    <div>
        <div class="box">
            <div class="box_textarea">
                <textarea v-model="publish_model.description" placeholder="Write a caption... " class="textarea"/>
            </div>
        </div>
        <div class="box_i">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="items_inf">
                            <div class="item_inf">
                                <div class="text_st1">Auto Post to Facebook</div>
                                <div>
                                    <div class="form-check form-switch">
                                        <button class="facebook-btn" :class="{active: auto_post_facebook}" @click="autoPostFacebook">Post</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box_select">
                            <div class="select_calendar" @click="select_calendar = !select_calendar">
                                <div class="title_s">
                                    Schedule
                                </div>
                                <div class="col-2 text-end">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="select_calendar" class="calendar_box">
                                <Calendar :selectedDateInput="getCurrentDate()" @selected_date="selectedDate"/>
                                <div class="time_date_mobile">
                                    <SelectTime @timeChanged="timeChanged"/>
                                </div>
                            </div>
                        </div>
                        <div class="box_select">
                            <div class="select_calendar" @click="select_order = !select_order">
                                <div class="title_s">
                                    Order
                                </div>
                                <div class="col-2 text-end">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-if="select_order" class="calendar_box">
                                <draggable v-model="images">
                                    <transition-group class="box_drop_items">
                                        <div class="box_list_img" v-for="element in images" :key="element.id">
                                            <img :src="element.src" alt="">
                                        </div>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                        <carousel v-if="isMobile"
                                  class="carousel-shopify p-2"
                                  :mouseDrag="false"
                                  :scrollPerPage="false"
                                  :perPage="1"
                                  :centerMode="true"
                                  :navigationEnabled="true"
                                  :paginationEnabled="false"
                                  :minSwipeDistance="10000"
                                  v-on:navigation-click="pageChange"
                                  :navigation-next-label="nextLabel"
                                  :navigation-prev-label="prevLabel">
                            <slide v-for="image in getImages">
                                <div class="box_img">
                                    <img class="" :src="image.image_url">
                                </div>
                                <div class="box_select" v-if="user_instagram && user_instagram.instagram_connect.product_tags == 1">
                                    <div class="select_calendar" @click="select_product = !select_product">
                                        <div class="title_s">
                                            Product Tagging
                                        </div>
                                        <div class="col-2 text-end">
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="select_product" class="calendar_box">
                                        <div class="box_search_i">
                                            <input @keyup="keypressSearchProduct" type="text" class="search_prod_inp" placeholder="name">
                                            <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                        </div>
                                        <h3 class="product-not-found" v-if="instagram_products.length === 0">Products not found</h3>
                                        <div v-for="prod in instagram_products" class="box_search_prod_shopify">
                                            <div class="prod_info">
                                                <div class="prod_info_item">{{ prod.product_name }}</div>
                                                <div class="prod_info_item prod_info_secondary">ID {{ prod.product_id }}</div>
                                            </div>
                                            <div class="prod_options_container">
                                                <div class="prod_image_item">
                                                    <img class="prod_image" v-if="prod.image_url" :src="prod.image_url" alt="">
                                                </div>
                                                <div class="prod_add_container">
                                                    <div class="box_add_p">
                                                        <button v-if="publish_model.tag_products[getImages[current_image].id] != undefined &&
                                                                    isInstagramProductSelected(prod.product_id, getImages[current_image].id)"
                                                                    class="btn_added_prod" @click="addProdModal(prod, getImages[current_image].id)">Added</button>
                                                        <button v-else-if="publish_model.tag_products[getImages[current_image].id] == undefined ||
                                                                    (publish_model.tag_products[getImages[current_image].id] != undefined &&
                                                                    publish_model.tag_products[getImages[current_image].id].length < 5)"
                                                                    class="btn_add_prod" @click="addProdModal(prod, getImages[current_image].id)">Add</button>
                                                        <button v-else class="btn_add_prod disabled" disabled>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                        <div v-if="isMobile" class="box_form2">
                            <div class="box_form2_item">
                                <div class="text_st1 w_text">Tag Users</div>
                                <input v-model="publish_model.tag_users" autocomplete="off" type="text" class="input_text_stile" placeholder="ex: user_name,user_name_2">
                            </div>
                        </div>
                        <br/>
                        <div v-if="isMobile" class="box_form2">
                            <div class="box_form2_item">
                                <div class="text_st1 w_text">Add Hashtags</div>
                                <input v-model="publish_model.hashtags" autocomplete="off" type="text" class="input_text_stile" placeholder="Type something...">
                            </div>
                        </div>
                    </div>
                    <div v-if="!isMobile" class="col">
                        <carousel class="carousel-shopify p-2"
                                  :mouseDrag="false"
                                  :scrollPerPage="false"
                                  :perPage="1"
                                  :centerMode="true"
                                  :navigationEnabled="true"
                                  :paginationEnabled="false"
                                  v-on:navigation-click="pageChange"
                                  :navigation-next-label="nextLabel"
                                  :navigation-prev-label="prevLabel">
                            <slide v-for="image in getImages">
                                <div class="box_img">
                                    <img :src="image.image_url">
                                </div>
                                <div class="box_select with-border" v-if="user_instagram && user_instagram.instagram_connect.product_tags == 1">
                                    <div class="select_calendar" @click="select_product = !select_product">
                                        <div class="title_s">
                                            Product Tagging
                                        </div>
                                        <div class="col-2 text-end">
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-if="select_product" class="calendar_box">
                                        <div class="box_search_i">
                                            <input @keyup="keypressSearchProduct" type="text" class="search_prod_inp" placeholder="name">
                                            <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                        </div>
                                        <h3 class="product-not-found" v-if="instagram_products.length === 0">Products not found</h3>
                                        <div v-for="(prod, num) in instagram_products">
                                            <div v-if="num < current_prods_page * 2 && num >= (current_prods_page - 1) * 2" class="box_search_prod_shopify">
                                                <div class="prod_info">
                                                    <div class="prod_info_item">{{ prod.product_name }}</div>
                                                    <div class="prod_info_item prod_info_secondary">ID {{ prod.product_id }}</div>
                                                </div>
                                                <div class="prod_options_container">
                                                    <div class="prod_image_item">
                                                        <img class="prod_image" v-if="prod.image_url" :src="prod.image_url" alt="">
                                                    </div>
                                                    <div class="prod_add_container">
                                                        <div class="box_add_p">
                                                            <button v-if="publish_model.tag_products[getImages[current_image].id] != undefined &&
                                                                    isInstagramProductSelected(prod.product_id, getImages[current_image].id)"
                                                                    class="btn_added_prod" @click="addProdModal(prod, getImages[current_image].id)">Added</button>
                                                            <button v-else-if="publish_model.tag_products[getImages[current_image].id] == undefined ||
                                                                    (publish_model.tag_products[getImages[current_image].id] != undefined &&
                                                                    publish_model.tag_products[getImages[current_image].id].length < 5)"
                                                                    class="btn_add_prod" @click="addProdModal(prod, getImages[current_image].id)">Add</button>
                                                            <button v-else class="btn_add_prod disabled" disabled>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 d-flex justify-content-between align-items-center">
                                            <button :disabled="current_prods_page <= 1"
                                                    class="btn-carousel"
                                                    :class="{disabled: current_prods_page <= 1}"
                                                    @click="current_prods_page--">Prev</button>
                                            <span class="mt-3">{{current_prods_page}}</span>
                                            <button :disabled="current_prods_page >= instagram_products.length / 2"
                                                    class="btn-carousel"
                                                    :class="{disabled: current_prods_page >= instagram_products.length / 2}"
                                                    @click="current_prods_page++">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                        <div class="p-2">
                            <div class="box_form2_item">
                                <div class="text_st1 w_text">Tag Users</div>
                                <input v-model="publish_model.tag_users" autocomplete="off" type="text" class="input_text_stile" placeholder="ex: user_name,user_name_2">
                            </div>
                            <br/>
                            <div class="box_form2_item">
                                <div class="text_st1 w_text">Add Hashtags</div>
                                <input v-model="publish_model.hashtags" autocomplete="off" type="text" class="input_text_stile" placeholder="ex: #babyonesie #babyfashion">
                            </div>
                        </div>
                        <div class="time_date" v-if="select_calendar">
                            <SelectTime @timeChanged="timeChanged"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_box">
            <button class="btn_post_n" @click="openModalInstagram">Publish</button>
        </div>
        <div class="modal" id="modalLoginInstagram" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Instagram</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                        <button class="btn_post_n" @click="loginInstagram">Connect account</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalSuccessPosting" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="title_mst2">Your post has been scheduled for</div>
                        <div class="title_mst1">{{ getDatePosted() }}</div>
                        <div class="box_md_t title_mst3">Would you like to continue working on the same project?</div>
                    </div>
                    <div class="modal-footer box_btn_modal mb-4">
                        <button class="btn_post_n2" @click="goToHome">No</button>
                        <button class="btn_post_n" data-bs-dismiss="modal" aria-label="Close">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalImagePosted" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="mt-5 title_mst3">Instagram will allow posts for up to 10 images. More than 10 images will be split into several posts.</div>
                    </div>
                    <div class="modal-footer box_btn_modal mb-4">
                        <button class="btn_post_n2" @click="publish">Okay</button>
                        <button class="btn_post_n" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalFacebookAutoPost" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="mt-5 title_mst3">Select page where you want create post</div>
                    </div>
                    <div class="modal-body">
                        <div v-for="page in facebook_pages" class="row">
                            <div class="col-8">{{page.name}} ({{page.category}})</div>
                            <div class="col-4">
                                <button class="facebook-btn" :class="{active: isInSelectedFacebookPages(page.id)}" @click="selectFacebookPage(page.id)">
                                    Select
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer box_btn_modal mb-4">
                        <button class="btn_post_n" data-bs-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalAddImagesToProduct" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="mt-5 title_mst3">Do you want to add/remove all images to selected product?</div>
                    </div>
                    <div class="modal-footer box_btn_modal mb-4">
                        <button class="btn_post_n" @click="addToOne">No</button>
                        <button class="btn_post_n" @click="addToAll">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainLayout from "../../layouts/MainLayout";
import Calendar from "../../components/Calendar";
import {errorMessage} from "../../services/messages";
import {mapMutations} from "vuex";
import {Modal} from 'bootstrap';
import moment from 'moment-timezone';
import draggable from 'vuedraggable';
import { Carousel, Slide } from 'vue-carousel';
import helpFunctions from "../../helpFunctions";
import SelectTime from "../SelectTime";
import ('../../css/custom.css');
import ('../../css/carousel.css');

export default {
    name: 'Instagram',
    components: {
        SelectTime,
        MainLayout,
        Calendar,
        draggable,
        Carousel,
        Slide,
    },
    props: {
        selectedImages: Array,
        brand_image: null,
        company_name: null
    },
    data() {
        return {
            user_instagram: false,
            am_pm: 'AM',
            hour: '0',
            minute: '00',
            selected_date: null,
            job: {},
            select_product: false,
            select_calendar: false,
            select_order: false,
            modalLoginInstagram: null,
            modalSuccessPosting: null,
            modalImagePosted: null,
            modalFacebookAutoPost: null,
            modalAddImagesToProduct: null,
            modal_date: null,
            date_m: null,
            instagram_products: [],
            current_image: 0,
            current_prods_page: 1,
            images: [],
            selected_product: null,
            selected_image_id: null,
            nextLabel: '<span class="btn-carousel btn-carousel_next">Next</span>',
            prevLabel: '<span class="btn-carousel btn-carousel_prev">Prev</span>',
            auto_post_facebook: false,
            facebook_pages: [],
            publish_model: {}
        }
    },
    mounted() {
        this.publish_model = helpFunctions.publish_model;
        this.publish_model.image_jobs_id = this.$route.params.id;
        this.modalLoginInstagram = new Modal(document.getElementById('modalLoginInstagram'));
        this.modalSuccessPosting = new Modal(document.getElementById('modalSuccessPosting'));
        this.modalImagePosted = new Modal(document.getElementById('modalImagePosted'));
        this.modalFacebookAutoPost = new Modal(document.getElementById('modalFacebookAutoPost'));
        this.modalAddImagesToProduct = new Modal(document.getElementById('modalAddImagesToProduct'));
        this.getJobs();
        this.getUserInstagram();
        this.selectedImages.forEach(el => {
            this.publish_model.tag_products[el] = [];
        });
        this.selected_date = this.getCurrentDate();
        this.hour = helpFunctions.getCurrentHour(5);
        this.minute = helpFunctions.getCurrentMinutes(5);
        this.am_pm = helpFunctions.getCurrentMeridiem(5);
    },
    destroyed() {
        if (this.modalLoginInstagram) {
            this.modalLoginInstagram = null;
        }
        if (this.modalSuccessPosting) {
            this.modalSuccessPosting = null;
        }
        if (this.modalImagePosted) {
            this.modalImagePosted = null;
        }
        if (this.modalFacebookAutoPost) {
            this.modalFacebookAutoPost = null;
        }
        if (this.modalAddImagesToProduct) {
            this.modalAddImagesToProduct = null;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        showModalConnect() {
            if (!this.user_instagram) {
                this.modalLoginInstagram.show();
            }
        },
        async getUserInstagram() {
            this.showLoader();
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}is-instagram-connect`);
                this.user_instagram = result?.data?.data || null;
                this.showModalConnect();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        openModalInstagram() {
            if (!this.user_instagram) {
                this.modalLoginInstagram.show();
                return;
            }
            this.modalImagePosted.show();
        },
        async loginInstagram() {
            this.showLoader();
            try {
                const res = await this.$http.getAuth(`${this.$http.apiUrl()}connect/instagram`);
                window.open(res?.data?.data, '_blank');
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        addProdModal(prod, image_id) {
            this.selected_product = prod;
            this.selected_image_id = image_id;
            this.modalAddImagesToProduct.show();
        },
        addToAll() {
            this.getImages.forEach(image => {
                this.addProd(this.selected_product, image.id);
            });
            this.modalAddImagesToProduct.hide();
        },
        addToOne() {
            this.addProd(this.selected_product, this.selected_image_id);
            this.modalAddImagesToProduct.hide();
        },
        addProd(prod, image_id) {
            let savedProduct = this.publish_model.tag_products[image_id].find(obj => {
                return obj.product_id === prod.product_id;
            });
            if (savedProduct === undefined) {
                if (this.publish_model.tag_products[image_id].length < 5) {
                    let added_prods = this.publish_model.tag_products;
                    added_prods[image_id].push({
                        product_id: prod.product_id,
                        product_name: prod.product_name,
                        image_url: prod.image_url
                    });
                    this.publish_model.tag_products = added_prods;
                }
            }
            else {
                let index = this.publish_model.tag_products[image_id].findIndex((obj) => obj.product_id === prod.product_id);
                if (index > -1) {
                    this.publish_model.tag_products[image_id].splice(index, 1);
                }
            }
            this.select_product = !this.select_product;
            this.select_product = !this.select_product;
        },
        isInstagramProductSelected(product_id, image_id) {
            let index = this.publish_model.tag_products[image_id].findIndex((obj) => obj.product_id === product_id);
            return index > -1;
        },
        selectFacebookPage(id) {
            let page = this.publish_model.facebook_pages.filter(obj => { return obj.id === id; });
            if (page.length <= 0) {
                this.publish_model.facebook_pages.push(this.facebook_pages.filter(obj => { return obj.id === id; })[0]);
            } else {
                this.publish_model.facebook_pages.splice(this.publish_model.facebook_pages.findIndex(obj => obj.id === id));
            }
            this.auto_post_facebook = this.publish_model.facebook_pages.length > 0;
        },
        isInSelectedFacebookPages(id) {
            return this.publish_model.facebook_pages.filter(obj => { return obj.id == id; }).length > 0;
        },
        async autoPostFacebook() {
            if (!this.user_instagram) {
                this.modalLoginInstagram.show();
                return;
            }
            if (!this.auto_post_facebook) {
                this.showLoader();
                try {
                    let resp = await this.$http.getAuth(`${this.$http.apiUrl()}search-pages-facebook`);
                    if (resp.data.data) {
                        this.facebook_pages = resp.data.data;
                    }
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
                this.modalFacebookAutoPost.show();
            } else {
                this.publish_model.facebook_pages = [];
                this.auto_post_facebook = false;
            }
        },
        async keypressSearchProduct(event) {
            let search_text = event.target.value;
            if (search_text && search_text.length > 2) {
                this.showLoader();
                try {
                    const result = await this.$http.postAuth(`${this.$http.apiUrl()}search-product-instagram`, { search: search_text });
                    this.instagram_products = result?.data?.data;
                } catch (e) {
                    const message = e?.response?.data?.error?.message || 'ERROR';
                    errorMessage(message);
                }
                this.hideLoader();
            } else {
                this.instagram_products = [];
            }
        },
        async getJobs() {
            this.showLoader();
            if (!this.$route.params.id) {
                return errorMessage('OOPS... No ID job!');
            }
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}get-job/${this.$route.params.id}`);
                this.job = result?.data?.data?.job || {};
                this.images = this.job.worked_image.filter(el => this.selectedImages.includes(el.id)).map(el => ({
                    id: el.id,
                    src: el.image_url
                }));
                /*this.images = this.job.images.filter(el => this.selectedImages.includes(el.worked_img.id)).map(el => ({
                    id: el.worked_img.id,
                    src: el.worked_img.image_url
                }));*/
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        getDatePosted() {
            return helpFunctions.getDatePosted(this.date_m, this.modal_date);
        },
        getCurrentDate() {
            return moment().format('YYYY-MM-DD');
        },
        goToHome() {
            this.modalSuccessPosting.hide();
            this.$router.push({to: '/', name: "Home"}).then();
        },
        async publish() {
            this.publish_model.date_publication = helpFunctions.getFormattedDateTime(this.selected_date, this.hour, this.minute, this.am_pm);
            this.publish_model.sequence_pictures = this.images.map(e => { return e.id; }) ?? [];
            this.publish_model.platform = 'instagram';
            console.log(this.publish_model);
            try {
                this.showLoader();
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}post-images`, this.publish_model);
                this.date_m = response?.data?.data || null;
                this.modal_date = this.hour + ':' + this.minute + '' + this.am_pm;
                this.modalImagePosted.hide();
                this.modalSuccessPosting.show();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        pageChange(index) {
            this.current_image = helpFunctions.pageChange(index, this.current_image, this.selectedImages.length);
        },
        selectedDate(data) {
            this.selected_date = data;
        },
        timeChanged(new_time) {
            this.hour = new_time[0];
            this.minute = new_time[1];
            this.am_pm = new_time[2];
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 992;
        },
        getImages() {
            if(this.job?.worked_image) {
                return this.job?.worked_image.filter(el => this.selectedImages.includes(el.id));
            }
            /*if (this.job?.images) {
                return this.job?.images.filter(el => this.selectedImages.includes(el.worked_img.id));
            }*/
        },
    }
}
</script>

<style lang="scss" scoped>
.col {
    width: 50%;
}
.product-not-found {
    margin: 24px 20px 0 20px;
}
.with-border {
    border: 1px solid #494949;
}
.disabled {
    cursor: default;
    opacity: 0.5;
}
.title_mst1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #494949;
}
.title_mst2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #494949;
}
.title_mst3 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #494949;
}
.box_md_t {
    margin-top: 30px;
    padding: 60px 20px;
    border-top: 1px solid #494949;
}
.box_btn_modal {
    display: flex;
    justify-content: center;
    gap: 40px;
}
.modal-footer {
    border-top: none;
}
.box_i {
    padding-top: 70px;
    width: 100%;
    display: flex;
}
.box {
    display: flex;
    margin-top: 64px;
    padding: 21px;
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    gap: 47px;
}
.box_img {
    //width: 313px;
    //height: 300px;
    width: 28%;
    height: auto;
}
.box_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.textarea {
    width: 100%;
    height: 300px;
    resize: none;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    padding-top: 20px;
}
textarea::-webkit-input-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}
textarea:-moz-placeholder {
    color: rgba(73, 73, 73, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}
.box_textarea {
    width: 72%;
    height: 100%;
}
.text_st1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.items_inf {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 0 55px 2px 35px;
}
.item_inf {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.input_text_stile {
    height: 30px;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 6px 19px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
}
.box_form2_item {
    display: flex;
    align-items: center;
}
.w_text {
    width: 60%;
}
.box_form2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 45px;
    padding-left: 35px;
}
.btn_box {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding-top: 170px;
    margin-bottom: 80px;
}
.select_calendar {
    display: flex;
    justify-content: space-between;
    padding: 21px 24px 21px 34px;
    height: 65px;
    align-items: center;
}
.box_select {
    cursor: pointer;
    margin-top: 27px;
    background: white;
    border-radius: 5px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.15));
}
.box_i {
    padding-top: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.calendar_box {
    padding: 33px;
}
.time_date_mobile {
    display: none;
}
.time_date {
    padding-top: 100px;
    display: flex;
    justify-content: center;
}
.box_list_img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.box_list_img {
    background: white;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    //width: 100%;
    //height: 100%;
    object-fit: cover;
}
.box_list_img {
    flex: 1 1 9em;
    max-width: 150px;
    height: 160px;
}
.box_drop_items {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    //width: 100%;
    //display: grid;
    //grid-gap: 20px;
    //grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
    //grid-template-rows: 150px 1fr;
}

@media only screen and (max-width: 992px) {
    .col {
        width: 100%;
        flex: auto;
    }
    .box_i {
        padding-top: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .btn_box {
        margin-bottom: 0px;
    }
    .box_list_img {
        max-width: 150px;
    }
    .box_drop_items {
        gap: 7px;
    }
    .box_btn_modal {
        gap: 5px;
    }
    .title_mst2 {
        font-size: 19px;
    }
    .title_mst1 {
        font-size: 21px;
    }
    .time_date_mobile {
        padding-top: 30px;
        display: flex;
        justify-content: center;
    }
    .calendar_box {
        padding: 14px 28px 30px 28px;
    }
    .w_text {
        width: 100%;
    }
    .btn_box {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    .box_form2 {
        padding-right: 17px;
    }
    .items_inf {
        padding: 43px 26px 0 30px;
    }
    .box_select {
        width: 100%;
    }
    .textarea {
        height: 90px;
        padding-top: 5px;
    }
    .box_img {
        width: 104px;
        height: 100px;
    }
    .box {
        margin-top: 26px;
        padding: 7px;
        gap: 17px;
    }
    .box_form2 {
        padding-left: 17px;
    }
}
.search_prod_inp {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 41px;
    width: 100%;
    padding-left: 45px;
}
.search_icon {
    position: absolute;
    left: 50px;
    top: 110px;
    cursor: pointer;

    @media only screen and (max-width: 992px) {
        top: 90px;
    }
}
.box_search_prod_shopify {
    margin-top: 33px;
    background: white;
    border: 0.2px solid #494949;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
}
.prod_info {
    width: 60%;
    margin: 10px;
}
.prod_info_item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: black;
    padding: 5px 0;
}
.prod_info_secondary {
    font-size: 12px;
    color: gray;
}
.prod_options_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: end;
}
.prod_image_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}
.prod_add_container {
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.prod_image {
    width: 100%;
    height: auto
}
.prod_add_container .box_add_p:last-child {
    padding: 10px 0 10px 5px;
    margin-right: 0;
}
.box_add_p {
    padding: 10px 5px;
    width: 35%;
    display: flex;
    justify-content: end;
    align-items: end;
    margin-right: 10px;
}
</style>
