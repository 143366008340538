<template>
    <div class="gallery-container">
        <vue-select-image
            ref="vue_select_image"
            :dataImages="allImages"
            :is-multiple="true"
            rootClass="myImg"
            :selectedImages="selectedImages"
            :limit="limit"
            @onselectmultipleimage="onSelectImage"/>
    </div>
</template>

<script>
import VueSelectImage from 'vue-select-image';
require('vue-select-image/dist/vue-select-image.css');
import ('../css/galleryWithSelect.css');

export default {
    name: "GalleryWithSelect",
    components: {
        VueSelectImage
    },
    props: {
        images: Array,
        limit: Number
    },
    data() {
        return {
            originalImages: [],
            selectedImages: [],
            allImages: [],
        }
    },
    methods: {
        onSelectImage(images) {
            this.selectedImages = images.map(el => el.id);
            this.$emit('updateSelectedImages', this.selectedImages);
        },
        selectAllImages() {
            this.$refs.vue_select_image.multipleSelected = this.originalImages.map(el => {
                if (el.src.match(/\.(zip|rar|7z)$/g)?.length > 0 || false) {
                    el.src = require('@/assets/icons/Archive-icon.jpg');
                }
                return el;
            });
            this.selectedImages = this.originalImages.map(el => el.id);
            this.$emit('updateSelectedImages', this.selectedImages);
        },
    },
    watch: {
        images(val) {
            this.originalImages = val;
            this.allImages = val.map(el => {
                if (el.src.match(/\.(zip|rar|7z)$/g)?.length > 0 || false) {
                    el.src = require('@/assets/icons/Archive-icon.jpg');
                }
                return el;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.gallery-container {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
}
</style>
